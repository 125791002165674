import * as CryptoJs from 'crypto-js'

export function useCrypto(cryptoSecretKeyParam: string) {
  const cryptoSecretKey = cryptoSecretKeyParam
  const cryptSettings = {
    iv: CryptoJs.enc.Utf8.parse(cryptoSecretKey),
    mode: CryptoJs.mode.ECB,
    padding: CryptoJs.pad.Pkcs7
  }

  const getCryptoKey = () => {
    return CryptoJs.enc.Utf8.parse(cryptoSecretKey)
  }

  const encryptString = (stringToEncrypt: string) => {
    const stringEncrypted = CryptoJs.AES.encrypt(stringToEncrypt, getCryptoKey(), cryptSettings).toString()
    return stringEncrypted
  }

  const decryptString = (stringToDecrypt: string) => {
    const stringDecrypted = CryptoJs.AES.decrypt(stringToDecrypt, getCryptoKey(), cryptSettings).toString(CryptoJs.enc.Utf8)
    return stringDecrypted
  }

  return {
    encryptString,
    decryptString
  }
}