/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    const sizes = {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
    return sizes;
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    if (isClient) {
      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;
