import { getApiSdk } from '@api-sdk';
import { Campaign } from '@interfaces/index';

/**
 *
 * TODO: Fix type response on js-api-sdk project from 'string' to correct campaign typed
 *
 */
export const getCampaign = async (
  id: string,
  sendsparkAppRendering?: boolean,
  host?: string,
  options?: any
): Promise<Campaign> => {
  const { data } = await getApiSdk.getCampaignsIdPublic(id, sendsparkAppRendering, host, options);

  return (data as unknown) as Campaign;
};

export const generateProtectedCampaignAuthAccess = async (shareId: string, password: string, options?: any) => {
  const { data } = await getApiSdk.generateAuthTokenForCampaignAccess(shareId, { password }, options)
  return data
}
