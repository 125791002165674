import { Dispatch } from 'react';
import { environmentsConfig } from '@config';
import { Actions } from './appActions';
import Pusher from 'pusher-js';

export type IsFeatureFlipEnabled = (feature: string) => boolean;

export type Action =
  | { type: Actions.ADD_FEATURE_FLIP; payload: string }
  | { type: Actions.START_CONFETTI }
  | { type: Actions.STOP_CONFETTI };

export type AppState = {
  isFeatureFlipEnabled: IsFeatureFlipEnabled;
  dispatch: Dispatch<Action>;
  isConfettiRunning: boolean;
  getPusher: () => Pusher | void;
};

export const defaultAppState: AppState = {
  isFeatureFlipEnabled: (feature) =>
    !!environmentsConfig?.featureFlips[feature],
  dispatch: () => {},
  isConfettiRunning: false,
  getPusher: () => {},
};

export function appReducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case Actions.START_CONFETTI: {
      return { ...state, isConfettiRunning: true };
    }
    case Actions.STOP_CONFETTI: {
      return { ...state, isConfettiRunning: false };
    }
    default:
      return state;
  }
}
