/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DefaultApi } from '@sendspark/js-api-sdk';
import { isVideoCreator } from '@utils/index';
import axios from 'axios';
import { useRef } from 'react';
import { useLocalStorageV2 } from './useLocalStorageV2';

export const useSegmentTracker = (apiV2Url: string, contextGroupId?: string, cryptoKey?: string) => {
  const apiRef = useRef<DefaultApi | null>(null);
  const { getLocalStorageV2 } = useLocalStorageV2(cryptoKey)
  const onRequest = (configParam: any) => {
    const newParams = configParam;
    newParams.baseURL = configParam.baseURL
      ? `${configParam.baseURL as string}/api`
      : `${apiV2Url}/api`;
    newParams.headers.accept = 'application/json';
    newParams.withCredentials = true;

    return newParams;
  };

  const onRequestError = (error: any) => Promise.reject(error);

  const axiosConfig = {
    baseURL: apiV2Url,
    withCredentials: true,
    headers: {
      [process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT || 'X-Snd-Value']:
        process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT_VALUE || 'Sendspark Share',
    },
  };

  const axiosClient = axios.create(axiosConfig);

  axiosClient.interceptors.request.use(onRequest, onRequestError);

  apiRef.current = new DefaultApi({}, '', axiosClient);

  const segmentTracker = async (
    name: string,
    data: Record<string, any> = {}
  ) => {
    try {
      // Only track if the viewer is not the creator of the video (check id on cookie and on storage if availabel for accuracy) 
      if (isVideoCreator(data?.creatorId, getLocalStorageV2)) return

      const anonymousId =
        window?.localStorage
          ?.getItem('ajs_anonymous_id')
          ?.replace(/['"]+/g, '') || window?.localStorage?.getItem('visitorId');
      if (!apiRef.current) throw Error('api is null');
      await apiRef.current.postEvents({
        name,
        data: { ...data, anonymousId, workspaceId: contextGroupId },
      
      });
    } catch (e) {
      console.warn(
        `Unable to track the event ${name} via API v2 events wrapper endpoint`
      );
    }
  };

  return segmentTracker;
};
