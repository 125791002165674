import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from '@recorder/hooks/useLocalStorage';

export function useVisitorId() {
  const [visitorId, setVisitorId] = useLocalStorage('visitorId', '');
  if (!visitorId) {
    setVisitorId(uuidv4());
  }

  return visitorId;
}
