import { getCampaign } from '@api-v2/campaigns';
import { EditPageUpdate, EnvConfig, Campaign } from '@interfaces';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useIsEditPage } from './useIsEditPage';
import { useAppContext } from 'context/appContext';

export function useEditPageUpdates(
  campaignId: string,
  env: EnvConfig,
  setCampaign: Dispatch<SetStateAction<Campaign>>,
  creatorId: string,
  version: string = ""
) {
  const [campaignUpdate, setCampaignUpdate] = useState<
    EditPageUpdate | undefined
  >(undefined);
  const isEditPage = useIsEditPage();
  const { getPusher } = useAppContext();

  useEffect(() => {
    if (isEditPage) {
      const pusher = getPusher()
      const editChannelStrategyPerVersion: { [k: string]: string } = {
        "v1": "edit-updates-" + creatorId
      }
      const videoReadyChannelStrategyPerVersion: { [k: string]: string } = {
        "v1": "video-ready-" + creatorId
      }
      const channel = pusher?.subscribe(editChannelStrategyPerVersion[version] || 'edit-updates');
      channel?.bind(campaignId, (data: EditPageUpdate) => {
        if (data.meta) {
          const videoReadyChannel = pusher?.subscribe(videoReadyChannelStrategyPerVersion[version] ?? 'video-ready');

          const updateCampaign = async () => {
            videoReadyChannel?.unbind(data.meta?.id, updateCampaign);

            setCampaign(
              await getCampaign(campaignId, undefined, undefined, {
                baseURL: env.apiV2Url,
              })
            );
          };

          videoReadyChannel?.bind(data.meta?.id, updateCampaign);
        }

        setCampaignUpdate(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return campaignUpdate;
}
