export interface EnvironmentsConfig {
  featureFlips: Record<string, boolean>;
  apiV2Url: string;
  apiV1Url: string;
  pusherKey: string;
  pusherCluster: string;
  nodeEnv: string;
  sendsparkSiteUrl: string;
  segmentApiKey: string;
  dashboardUrl: string;
  appUrl: string;
  editPageUrl: string;
  cryptoKey: string;
}

const checkFeatureFlip = (feature?: string) => feature === 'true';

export const environmentsConfig: EnvironmentsConfig = {
  featureFlips: {
    newRecorder: checkFeatureFlip(process.env.NEXT_PUBLIC_FF_NEW_RECORDER),
  },
  apiV2Url: process.env.NEXT_PUBLIC_API_V2_URL || '',
  apiV1Url: process.env.NEXT_PUBLIC_API_V1_URL || '',
  pusherKey: process.env.NEXT_PUBLIC_PUSHER_KEY || '',
  pusherCluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER || '',
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV || 'development',
  sendsparkSiteUrl: process.env.NEXT_PUBLIC_SENDSPARK_SITE || '',
  segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || '',
  dashboardUrl: process.env.DASHBOARD_URL || '',
  appUrl: process.env.APP_URL || '',
  editPageUrl: process.env.NEXT_PUBLIC_EDIT_PAGE_URL || '',
  cryptoKey: process.env.NEXT_PUBLIC_CRYPTO_KEY || ''
};
