// import { getCampaign } from '@api-v2/campaigns';
import { PreviewTemplatePageUpdate, EnvConfig } from '@interfaces';
import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';

export function usePreviewTemplatePageUpdates(
  templateId: string,
  env: EnvConfig
) {
  const [templateUpdate, setTemplateUpdate] = useState<
    PreviewTemplatePageUpdate | undefined
  >(undefined);

  useEffect(() => {
    const pusher = new Pusher(env.pusherKey as string, {
      cluster: env.pusherCluster,
    });
    const channel = pusher.subscribe('template-updates');
    channel.bind(templateId, (data: PreviewTemplatePageUpdate) => {
      setTemplateUpdate(data);
    });
  }, [templateId, env.pusherCluster, env.pusherKey]);

  return templateUpdate;
}
