/* eslint-disable */
import { useGetScriptProperties } from "./useGetScriptProperties";
import { Helmet } from "react-helmet";
import { render } from "react-dom";
import Script from "next/script";

export const useCreateIntegrationsByPosition = (
  userIntegrations: Array<any>
) => {
  const getScriptProperties = useGetScriptProperties();

  userIntegrations.map((integration: any) => {
    const { trackingId } = integration;
    integration.codeSnippets?.map(
      (codeSnippet: { position: string; snippet: string }) => {
        const newSnippet = codeSnippet.snippet.replace("${trackingId}", trackingId);
        const snippet = getScriptProperties(newSnippet);
        if (codeSnippet.position === "header") {
          const head = document.querySelector("head") as HTMLElement;
          const container = document.createElement("script");
          const containerPosition = head?.children.length + 1;
          head.insertBefore(container, head.children[containerPosition]);

          render(
            <Helmet>
              <script type={snippet.type} id={snippet.id} src={snippet.src}>
                {snippet.contain}
              </script>
            </Helmet>,
            container
          );
        } else if (codeSnippet.position === "body") {
          const body = document.querySelector("body") as HTMLElement;
          const container = document.createElement("script");
          body.appendChild(container);

          render(
            <Script
              type={snippet.type}
              id={snippet.id}
              src={snippet.src}
              dangerouslySetInnerHTML={{ __html: snippet.contain }}
            />,
            container
          );
        } else if (codeSnippet.position === "footer") {
          const body = document.querySelector("body") as HTMLElement;
          const container = document.createElement("script");
          const containerPosition = body?.children.length + 1;
          body.insertBefore(container, body.children[containerPosition]);

          render(
            <Script
              type={snippet.type}
              id={snippet.id}
              src={snippet.src}
              dangerouslySetInnerHTML={{ __html: snippet.contain }}
            />,
            container
          );
        }
      }
    );
  });

  return null;
};
