interface ViralLinkProps {
  campaignId: string;
  referralCode: string;
  workspaceName: string;
  url?: string;
  currentSite: 'request page' | 'share page'| 'end of video';
}
export function useViralLink({
  campaignId,
  referralCode,
  workspaceName,
  url = process.env.NEXT_PUBLIC_SENDSPARK_SITE || '',
  currentSite,
}: ViralLinkProps) {
  let viralLink = `${url}?`;
  if (referralCode) {
    viralLink += `via=${referralCode}&`;
  }
  if (workspaceName) {
    viralLink += `utm_source=viral&utm_medium=${currentSite}&utm_campaign=${workspaceName}&utm_term=${campaignId}`;
  }

  return encodeURI(viralLink);
}
