import { useContext, createContext, FC, useReducer, ReactNode, useState, useEffect } from 'react';
import { appReducer, AppState, defaultAppState } from './appReducer';
import Pusher from 'pusher-js';

const AppContext = createContext<AppState | undefined>(undefined);

const AppProvider: FC<{ children: ReactNode, pusherKey: string, pusherCluster: string}> = ({ children, pusherKey, pusherCluster, ...rest }) => {
  const [state, dispatch] = useReducer(appReducer, {
    ...defaultAppState,
  });

  const [pusher, setPusher] = useState<Pusher | undefined>(undefined);

  const getPusher = () => {
    if (!pusher && pusherKey && pusherCluster) {
      // Init pusher just one time to be used for any subscription
      const pusherInstance = new Pusher(pusherKey, { cluster: pusherCluster })
      setPusher(pusherInstance);
      return pusherInstance
    }
    return pusher
  }

  useEffect(() => {
    return () => {
      pusher?.unbind_all()
      pusher?.disconnect()
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
        getPusher
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(
      'App components cannot be rendered outside the AppProvider components'
    );
  }
  return context;
};

export { AppProvider, AppContext, useAppContext };
