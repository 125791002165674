import { useCrypto } from './useCrypto'

export function useLocalStorageV2(cryptoSecretKeyParam?: string) {
  const { encryptString, decryptString } = useCrypto(cryptoSecretKeyParam || '')

  const setLocalStorage = ({
    key,
    value,
    crypto = false,
    expire = false,
    hoursExpiration = 1,
  }: {
    key: string;
    value: string;
    crypto?: boolean;
    expire?: boolean;
    hoursExpiration?: number;
  }) => {
    try {
      if (!window || !window.localStorage) {
        return false
      }
      
      if (expire) {
        const expirationDate = new Date().getTime() + (hoursExpiration * 60 * 60 * 1000)
        const item = {
          value: crypto ? encryptString(value) : value,
          expirationDate
        }
  
        return window.localStorage.setItem(key, JSON.stringify(item))
      }
  
      if (crypto) {
        value = encryptString(value)
      }
  
      return window.localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      return false
    }
  }
  
  const getLocalStorageV2 = ({
    key,
    crypto = false
  }: {
    key: string;
    crypto?: boolean;
  }) => {
    try {
      if (!window || !window.localStorage) {
        return null
      }
  
      const storedItem = window.localStorage.getItem(key)
  
      if (!storedItem) {
        return null
      }
  
      const parsedItem = JSON.parse(storedItem)
      const { value, expirationDate } = parsedItem
  
      if (!value && !expirationDate) {
        return crypto ? decryptString(parsedItem) : parsedItem
      }
  
      const currentTimestamp = new Date().getTime()
      if (currentTimestamp >= expirationDate) {
        window.localStorage.removeItem(key)
        return null
      }
  
      return  crypto ? decryptString(value) : value
    } catch (e) {
      return null
    }
  }

  return {
    setLocalStorage,
    getLocalStorageV2
  }
}
