/* eslint-disable */

interface ScriptPropetiesProps {
  contain: string;
  src: string;
  type: string;
  id: string;
}

export const useGetScriptProperties = (): (textScript: string) => ScriptPropetiesProps => {
  const getScriptProperties = (textScript: string): ScriptPropetiesProps => {
    const contain = textScript.replace(/<\/?[^>]+(>|$)/g, "");
    const srcArray = (/<script.*?src="(.*?)"/ as any)?.exec(textScript);
    const src = srcArray?.length > 0 && srcArray[1];
    const typeArray = (/<script.*?type="(.*?)"/ as any)?.exec(textScript);
    const type = typeArray?.length > 0 && typeArray[1];
    const idArray = (/<script.*?id="(.*?)"/ as any)?.exec(textScript);
    const id = idArray?.length > 0 && idArray[1];

    return {
      contain,
      src,
      type,
      id,
    };
  }

  return getScriptProperties;
}