/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DefaultApi } from '@sendspark/js-api-sdk';
import axios from 'axios';

export const getApiSdk = (() => {
  const apiBaseUrl = process.env.NEXT_PUBLIC_API_V2_URL;

  const onRequest = (configParam: any) => {
    const newParams = configParam;
    newParams.baseURL = configParam.baseURL
      ? `${configParam.baseURL}/api`
      : `${apiBaseUrl}/api`;
    newParams.headers.accept = 'application/json';
    newParams.withCredentials = true;
    newParams.headers[
      process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT || 'X-Snd-Value'
    ] = process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT_VALUE || 'Sendspark Share';

    return newParams;
  };

  const onRequestError = (error: any) => Promise.reject(error);

  const axiosConfig = {
    baseURL: apiBaseUrl,
    withCredentials: true,
    headers: {
      [process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT || 'X-Snd-Value']:
        process.env.NEXT_PUBLIC_CUSTOM_USER_AGENT_VALUE || 'Sendspark Share',
    },
  };

  const axiosClient = axios.create(axiosConfig);

  axiosClient.interceptors.request.use(onRequest, onRequestError);

  return new DefaultApi({}, '', axiosClient);
})();

export default getApiSdk;
